@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "montserrat-semibold";
  src: url("/fonts/Montserrat-SemiBold.ttf");
}

@font-face {
  font-family: "montserrat-regular";
  src: url("/fonts/Montserrat-Regular.ttf");
}

@font-face {
  font-family: "montserrat-light";
  src: url("/fonts/Montserrat-Light.ttf");
}

html {
  scroll-behavior: smooth;
}
